import BackendService from './BackendService';
const singleName = 'seo-general';
//const pluralName = singleName+'s';




export default class SeoGeneralService extends BackendService {

    constructor() {
        super();
    }
   

    create(data) {


        //console.log('esto se envio::',  data);
        //return data;
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

    }

    update(data) {
       //console.log('contrato, con: ', contract)
        return this.httpCall(singleName+'/'+data.id,{
            method: 'PUT',
            data: data
        });
    }
    toggleStatus(id){
        return this.httpCall(singleName+'/'+id+'/toggle',{
            method: 'PUT',
        });
    }

    delete(id) {
        return this.httpCall(singleName+'/'+id,{
            method: 'delete'
        });
    }

    getById(id) {

        return this.httpCall(singleName+'/'+id);

        //return this.httpCall('property' + property_id + '/users');
    }

    getList(params) {
        //console.log('get rooms');

        //return testItems;
        return this.httpCall(singleName,{
            params: params,
        });
    }
    

}
